<template>
  <div>
    <Header title="設定安全密碼" theme="light" :step="-1" />
    <Container>
      <FormContainer :formData="formData" @submitForm="submitForm" />
    </Container>
  </div>
</template>

<script>
import Container from "../components/Container";
import FormContainer from "../components/FormContainer";
import { Message } from "element-ui";
import { mapActions } from "vuex";
import Header from "@/components/Header.vue";
export default {
  name: "security",
  components: {
    Container,
    FormContainer,
    Header
  },
  data() {
    return {
      formData: [
        {
          label: "新密码",
          key: "password",
          type: "password",
          placeholder: "请输入新密码",
          value: "",
        },
        {
          label: "确认新密码",
          key: "newPassword",
          type: "password",
          placeholder: "请再次输入密码",
          value: "",
        },
      ],
    };
  },
  methods: {
    ...mapActions("security", ["postSecurity"]),
    submitForm(form) {
      let data = {};
      for (var i = 0; i < form.length; i++) {
        data[form[i].key] = form[i].value;
      }
      let { password, newPassword } = data;
      if (password.length < 6 || newPassword.length < 6) {
        Message({
          message: "密码长度不能小于6位 !",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else if (password !== newPassword) {
        Message({
          message: "两次输入的密码不一致 !",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else if (password === newPassword) {
        this.postSecurity({
          securityCode: newPassword,
        }).then((res) => {
          if (res.code === "ok") {
            this.$router.go(-1);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  padding: 0rem 1.2rem 0 1.2rem;
  box-sizing: border-box;
}

.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.justify_content_between {
  justify-content: space-between;
}

.item {
  width: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
}

.icon {
  display: block;
  width: 1rem;
  transform: translateY(0.1rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}

.form_label {
  margin-right: 0.5rem;
}

.form_control {
  flex: 1;
  padding: 0.75rem 0rem;
  border: none;
}

::placeholder {
  color: var(--light-gray);
  font-size: 1rem;
}

input:focus {
  outline: none;
}

.btn {
  &_default {
    display: inline-block;
    align-self: center;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--light-gray);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 1.25rem;
    width: 80vw;
    margin-top: 5rem;
  }

  &_primary {
    color: white;
    background-color: var(--orange);
  }
}
</style>